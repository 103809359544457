.prompt-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.prompt-container {
  min-width: 150px;
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  overflow: auto;
  user-select: auto;
}

.prompt-container p {
  margin-bottom: 10px;
}

.prompt-container input {
  margin-bottom: 10px;
  width: 80%;
}
