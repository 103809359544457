.flow-table {
  display: grid;
  grid-template-columns: repeat(9, auto);
  gap: 0.5rem;
}

.download-file-table {
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 0.5rem;
}

.header-row > div,
.flow-row > div {
  padding: 0.5rem;
}

.header-row {
  font-weight: bold;
  text-align: center;
}

.svg-container {
  text-align: center;
}

.svg-container svg {
  display: block;
  margin-inline: auto;
}
